import React from 'react';
import { useTranslation } from 'next-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSession } from 'next-auth/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { showErrorAlert, showSuccessAlert } from '@/modules/alerts';
import { useSendContactMessage } from '@/services/contact';
import { Asterisk, Button, Textarea, Textfield } from "@/components/atoms";
import { GlobalError } from '@/styles/common';
import { Column, CustomForm, Row } from './ContactForm.styles';

type ContactFormType = {
    contact_name: string;
    contact_subject: string;
    contact_email: string;
    contact_message: string;
};

const ContactForm: React.FC = () => {
    const { t } = useTranslation(); // Translations
    const { executeRecaptcha } = useGoogleReCaptcha(); // Recaptcha
    const { data: session } = useSession(); // Get current session
    const user = session?.user;
    const [globalError, setGlobalError] = React.useState<string>('');
    const { sendContactMessage, loading } = useSendContactMessage();

    // Form initial values
    const initialValues = {
        contact_name: user?.firstName || '',
        contact_subject: '',
        contact_email: user?.email || '',
        contact_message: '',
    };

    // Validation schema
    const validationSchema = Yup.object({
        contact_name: Yup.string().required(t('contact:required.name')),
        contact_subject: Yup.string().required(t('contact:required.subject')),
        contact_email: Yup.string().email(t('contact:invalid.email')).required(t('contact:required.email')),
        contact_message: Yup.string().required(t('contact:required.message')),
    });

    const handleError = async () => {
        await showErrorAlert({
            title: t('contact:error.message.title'),
            text: t('contact:error.message.text'),
            confirmButtonText: t('contact:button.close'),
        });
    };

    // Create an event handler, so you can call the verification on form submit
    const handleOnSubmit = async (
        values: ContactFormType,
        {
            setFieldError,
            resetForm,
        }: {
            setFieldError: (field: string, message: string | undefined) => void;
            resetForm: () => void;
        }
    ) => {
        setGlobalError('');

        if (executeRecaptcha) {
            const token = await executeRecaptcha('SEND_CONTACT_MESSAGE');

            await sendContactMessage({
                variables: {
                    name: values.contact_name,
                    subject: values.contact_subject,
                    email: values.contact_email,
                    message: values.contact_message,
                    recaptchaToken: token,
                },
                onCompleted: async (data) => {
                    if (data?.sendContactMessage?.status === 200) {
                        await showSuccessAlert({
                            title: t('contact:success.message.title'),
                            text: t('contact:success.message.text'),
                            confirmButtonText: t('contact:button.confirm'),
                            onConfirm: () => {
                                resetForm();
                            },
                        });
                    } else {
                        if (data?.sendContactMessage?.errors && data?.sendContactMessage?.errors?.length > 0) {
                            data?.sendContactMessage?.errors?.map((err) => {
                                const field = 'contact_' + err?.field || '';
                                const message = err?.messages.join(',') || '';
                                if (!field) {
                                    setGlobalError(message);
                                } else {
                                    setFieldError(field, message);
                                }
                            });
                        } else {
                            await handleError();
                        }
                    }
                },
                onError: async () => {
                    await handleError();
                },
            });
        }
    };

    return (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
            {({ errors, values, touched, handleChange, handleBlur }) => {
                return (
                    <CustomForm>
                        <Row>
                            <Column>
                                <Textfield
                                    id="contact_name"
                                    type="text"
                                    placeholder={t('contact:placeholder.name')}
                                    label={<Asterisk text={t('contact:label.name')} />}
                                    onChange={handleChange}
                                    editable={!user?.firstName}
                                    onBlur={handleBlur}
                                    value={values?.contact_name}
                                    errors={touched.contact_name && errors.contact_name}
                                    success={(touched.contact_name || values?.contact_name !== '') && !errors.contact_name}
                                />
                                <Textfield
                                    id="contact_email"
                                    type="text"
                                    placeholder={t('contact:placeholder.email')}
                                    label={<Asterisk text={t('contact:label.email')} />}
                                    onChange={handleChange}
                                    editable={!user?.email}
                                    onBlur={handleBlur}
                                    value={values?.contact_email}
                                    errors={touched.contact_email && errors.contact_email}
                                    success={(touched.contact_email || values?.contact_email !== '') && !errors.contact_email}
                                />
                                <Textfield
                                    id="contact_subject"
                                    type="text"
                                    placeholder={t('contact:placeholder.subject')}
                                    label={<Asterisk text={t('contact:label.subject')} />}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.contact_subject}
                                    errors={touched.contact_subject && errors.contact_subject}
                                    success={(touched.contact_subject || values?.contact_subject !== '') && !errors.contact_subject}
                                />
                            </Column>
                            <Column>
                                <Textarea
                                    id="contact_message"
                                    placeholder={t('contact:placeholder.message')}
                                    label={<Asterisk text={t('contact:label.message')} />}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.contact_message}
                                    errors={touched.contact_message && errors.contact_message}
                                    success={(touched.contact_message || values?.contact_message !== '') && !errors.contact_message}
                                />
                            </Column>
                        </Row>
                        <GlobalError>{globalError}</GlobalError>
                        <Button content={t('contact:button.send-message')} variant="filled" width="158px" type="submit" isSubmitting={loading} disabled={loading} />
                    </CustomForm>
                );
            }}
        </Formik>
    );
};

export default ContactForm;
