import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { CONTACT_US } from '@/graphql/mutations';
import { SendContactUsMutation, SendContactUsMutationVariables } from '@/graphql/types';

export const useSendContactMessage = () => {
    const { i18n } = useTranslation(); // Translations
    const [contactUsMutation, { loading, error }] = useMutation<SendContactUsMutation, SendContactUsMutationVariables>(CONTACT_US, {
        context: { headers: { 'X-Language': i18n.language } },
    });

    const sendContactMessage = async ({
        variables,
        onCompleted,
        onUpdate,
        onError,
    }: {
        variables?: SendContactUsMutationVariables;
        onCompleted?: (data?: SendContactUsMutation) => Promise<void>;
        onUpdate?: (status?: number | null, errors?: ({ field: string; messages: string[] } | null)[] | null) => void;
        onError?: (error: unknown) => Promise<void>;
    }) => {
        try {
            const { data } = await contactUsMutation({
                variables,
                update: (cache, result) => {
                    const { status, errors } = result?.data?.sendContactMessage || {};
                    if (onUpdate) onUpdate(status, errors);
                },
                onCompleted: (dat) => {
                    if (onCompleted) void onCompleted(dat);
                },
                onError: (err) => {
                    if (onError) void onError(err);
                },
            });

            return data?.sendContactMessage;
        } catch (err) {
            if (onError) void onError(err);
            throw err;
        }
    };

    return { sendContactMessage, loading, error };
};
