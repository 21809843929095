import styled from 'styled-components';
import { Form } from 'formik';

export const CustomForm = styled(Form)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${({ theme }) => theme.media.lg`
        flex-direction: row;
        gap: 20px;
    `}
`;

export const Column = styled.div`
    width: 100%;
`;

