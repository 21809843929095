import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { SLIDES_QUERY } from '@/graphql/queries';
import { SlidesQuery, SlideType } from '@/graphql/types';

export const useSlides = (section: string) => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<SlidesQuery>(SLIDES_QUERY, {
        variables: {
            report: true,
            searchFields: [{ field: 'SECTION', operation: 'EQUAL', value: section }],
        },
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { slides: data?.slides?.objects as SlideType[], loading, error };
};
