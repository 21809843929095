import React from 'react';
import { useTranslation } from 'next-i18next';
import { Section } from '@/components/molecules';
import ContactForm from '@/forms/contact-form';

const ContactFormSection: React.FC = () => {
    const { t } = useTranslation(); // Translations

    return (
        <Section id="contact" title={t('contact:section.title')} container="compacted">
            <ContactForm />
        </Section>
    );
};

export default ContactFormSection;
