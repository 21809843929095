import { useTranslation } from 'next-i18next';
import { GetServerSidePropsContext } from 'next/types';
import { ApolloClient, NormalizedCacheObject, useQuery } from '@apollo/client';
import { DESTINATION_BY_SLUG_QUERY, DESTINATIONS_MOST_POPULAR_QUERY, DESTINATIONS_QUERY, DESTINATIONS_RECENTLY_QUERY, MOMENT_DESTINATIONS_QUERY } from '@/graphql/queries';
import { DestinationBySlugQuery, DestinationsMostPopularsQuery, DestinationsQuery, DestinationsRecentlyQuery, DestinationType, MomentDestinationsQuery } from '@/graphql/types';
import { useSessionCookie } from '@/modules/session-cookie';

export const useDestinations = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<DestinationsQuery>(DESTINATIONS_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { destinations: data?.destinations?.objects as DestinationType[], loading, error };
};

export const useDestinationsMostPopulars = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<DestinationsMostPopularsQuery>(DESTINATIONS_MOST_POPULAR_QUERY, {
        variables: {
            size: 10,
        },
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { destinations: data?.destinationsMostPopulars?.objects as DestinationType[], loading, error };
};

export const useMomentDestinations = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<MomentDestinationsQuery>(MOMENT_DESTINATIONS_QUERY, {
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { destinations: data?.momentDestinations?.objects as DestinationType[], loading, error };
};

export const useDestinationsRecently = () => {
    const { i18n } = useTranslation(); // Translations
    const { sessionID } = useSessionCookie();

    const { data, loading, error } = useQuery<DestinationsRecentlyQuery>(DESTINATIONS_RECENTLY_QUERY, {
        variables: {
            size: 10,
        },
        context: {
            headers: {
                'X-Language': i18n.language,
                'X-Session-Id': sessionID,
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    return { destinations: data?.destinationsRecently?.objects as DestinationType[], loading, error };
};

export const useRelatedDestinations = () => {
    const { i18n } = useTranslation(); // Translations

    // TODO: Change by related destinations query
    const { data, loading, error } = useQuery<DestinationsMostPopularsQuery>(DESTINATIONS_MOST_POPULAR_QUERY, {
        variables: {
            size: 10,
        },
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { destinations: data?.destinationsMostPopulars?.objects as DestinationType[], loading, error };
};

export const getDestinationBySlug = async (slug: string, apolloClient: ApolloClient<NormalizedCacheObject>, context: GetServerSidePropsContext) => {
    const { data, loading, error } = await apolloClient.query<DestinationBySlugQuery>({
        query: DESTINATION_BY_SLUG_QUERY,
        variables: {
            slug,
        },
        context: {
            headers: {
                'X-Language': context?.locale,
            },
        },
        fetchPolicy: 'no-cache',
    });

    return {
        destination: data?.destinationBySlug?.object,
        status: data?.destinationBySlug?.status,
        loading,
        error,
    };
};

export const useDestinationBySlug = (slug: string) => {
    const { i18n } = useTranslation(); // Translations
    const { sessionID } = useSessionCookie();

    const { data, client, loading, error } = useQuery<DestinationBySlugQuery>(DESTINATION_BY_SLUG_QUERY, {
        variables: {
            slug,
        },
        context: {
            headers: {
                'X-Language': i18n.language,
                'X-Session-Id': sessionID,
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    // Fetch from the cache
    const dataOnCache = client.readQuery({
        query: DESTINATION_BY_SLUG_QUERY,
        variables: {
            slug,
        },
    });

    return {
        destination: data?.destinationBySlug?.object,
        status: data?.destinationBySlug?.status,
        loading,
        error,
        dataOnCache,
    };
};
