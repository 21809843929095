import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useSlides } from '@/services/slides';
import { Carousel } from '@/components/organisms';
import { CustomSlideType } from '@/components/organisms/Carousel';
import { SlideType } from '@/graphql/types';

type StaticSlideSection = 'cookies_policy' | 'privacy_policy' | 'terms_and_conditions' | 'return_policy' | 'destinations' | 'flights';

export type OmittedInSlideType =
    | 'title'
    | 'subtitle'
    | 'image'
    | 'buttonLink'
    | 'buttonText'
    | 'section'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
    | 'isDeleted'
    | 'deletedAt'
    | 'deletedBy'
    | 'isActive';

export type StaticSlideType = Omit<SlideType, OmittedInSlideType> & {
    image: {
        url: string;
        position?: string;
    };
    title?: string;
    subtitle?: string;
    buttonText?: string;
    buttonLink?: string;
};

type SlideSectionType = {
    [key in StaticSlideSection]: StaticSlideType[];
};

interface CarouselSectionProps {
    section: string;
    slides?: SlideType[] | StaticSlideType[];
    height?: number;
}

const PreloadedDynamicCarouselSection: React.FC<CarouselSectionProps> = ({ slides, height }) => {
    const theme = useTheme(); // Get current theme

    return <Carousel slides={slides as CustomSlideType[]} isDynamic overlay={theme.overlays.overlay1} height={height} />;
};

const DynamicCarouselSection: React.FC<CarouselSectionProps> = ({ section, height }) => {
    const { slides } = useSlides(section);
    const theme = useTheme(); // Get current theme

    return <Carousel slides={slides as CustomSlideType[]} isDynamic overlay={theme.overlays.overlay1} height={height} />;
};

const StaticCarouselSection: React.FC<CarouselSectionProps> = ({ section, height }) => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme

    const slides: SlideSectionType = {
        cookies_policy: [
            {
                id: '1',
                title: t('carousel:cookie_policy.slide1.title'),
                subtitle: t('carousel:cookie_policy.slide1.subtitle'),
                image: {
                    url: '/images/carousel/cookie_policy.jpg',
                },
            },
        ],
        privacy_policy: [
            {
                id: '1',
                title: t('carousel:privacy_policy.slide1.title'),
                subtitle: t('carousel:privacy_policy.slide1.subtitle'),
                image: {
                    url: '/images/carousel/privacy_policy.jpg',
                },
            },
        ],
        terms_and_conditions: [
            {
                id: '1',
                title: t('carousel:terms_and_conditions.slide1.title'),
                subtitle: t('carousel:terms_and_conditions.slide1.subtitle'),
                image: {
                    url: '/images/carousel/terms_and_conditions.jpg',
                },
            },
        ],
        return_policy: [
            {
                id: '1',
                title: t('carousel:return_policy.slide1.title'),
                subtitle: t('carousel:return_policy.slide1.subtitle'),
                image: {
                    url: '/images/carousel/return_policy.jpg',
                },
            },
        ],
        destinations: [
            {
                id: '1',
                title: t('carousel:destinations.slide1.title'),
                subtitle: t('carousel:destinations.slide1.subtitle'),
                image: {
                    url: '/images/carousel/destinations.jpg',
                    position: 'bottom center',
                },
            },
        ],
        flights: [
            {
                id: '1',
                image: {
                    url: '/images/carousel/flights.jpg',
                    position: 'center center',
                },
            },
        ],
    };

    return <Carousel slides={slides[section as StaticSlideSection] as CustomSlideType[]} isDynamic={false} overlay={theme.overlays.overlay1} height={height} />;
};

const CarouselSection: React.FC<CarouselSectionProps> = ({ section, slides, height }) => {
    const staticSections = ['cookies_policy', 'privacy_policy', 'terms_and_conditions', 'return_policy', 'destinations', 'flights'];

    if (slides) return <PreloadedDynamicCarouselSection section={section} slides={slides} height={height} />;

    if (staticSections.includes(section)) {
        return <StaticCarouselSection section={section} height={height} />;
    } else {
        return <DynamicCarouselSection section={section} height={height} />;
    }
};

export default CarouselSection;
